import React, {useState, useEffect, Fragment} from 'react'
import styled from 'styled-components'
import { Location } from '@reach/router'
import { uiConstants } from './../../../utils/ui-constants'
import { breakpoints } from './../../../utils/theme'
import Layout from './../common/Layout'
import HeroForm from './HeroForm'
import HomepageHeroGraphicMobile from './../../images/hero-graphic-mobile.svg'
import WavesBottom from './../../images/waves.gif'
import Cross from './../../images/cross.png'
import SupportersList from './SupportersList'
import SponsorsList from './SponsorsList'

const HomepageHero = (props) => {
    const [language, setLanguage] = useState(
        props.location.pathname.split("/")[1]
    );
    return (
        <StyledHomepageHero>
            <Layout width="main-width">
                <div className="hero-content" data-aos="fade-up">
                    <SupportersList />
                    <h1>{uiConstants[language].PressRelease.title}</h1>
                    {uiConstants[language].PressRelease.content.map((element, key) => (
                        <Fragment key={key}>
                            {element}
                        </Fragment>
                    ))}
					<br/>
                    <HeroForm />
                    <SponsorsList />
                </div>
            </Layout>
        </StyledHomepageHero>
    )
}

const StyledHomepageHero = styled(Layout)`
    position: relative;
    padding: 30px 0 120px;
    iframe {
        width: 600px;
        height: 1000px;
        #root {
            display: none;
        }
    }
    .hero-content {
        padding: 0 0 0 152px;
        position: relative;
        z-index: 1;
        max-width: 880;
        width: 100%;
        h1 {
            font-size: 50px;
            font-weight: 700;
            color: black;
            line-height: 60px;
            max-width: 982px;
        }
        h2 {
            font-size: 32px;
            margin-top: 80px;
        }
        .sub-heading {
            max-width: 880px;
            margin-top: 24px;
            font-size: 28px;
            font-weight: 300;
            line-height: 42px;
            color: rgba(28, 51, 84, 1);
        }
        .content-list {
            list-style: unset;
            padding-left: 80px;
            margin-top: 20px;
            li {
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;
                margin-bottom: 18px;
            }
        }
        p {
			max-width: 880px;
			margin-top: 24px;
			font-weight: 400;
            font-size: 20px;
            line-height: 30px;
		}
        em {
            font-weight: 400;
        }
        .hero-block {
            margin-bottom: 38px;
            display: flex;
            align-items: center;
            .logo-list-label {
                color: rgba(2, 26, 59, 1);
                font-weight: 400;
                font-size: 14px;
                margin-right: 26px;
                white-space: nowrap;
            }
            &.upper-block {
                .logo {
                    position: relative;
                    padding: 0 26px;
                    img {
                        max-width: 146px;
                        max-height: 58px;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: calc(50% - 10px);
                        height: 20px;
                        width: 1px;
                        background: linear-gradient(266.75deg, #F9DE32 22.53%, #FBB040 106.6%);
                    }
                }
            }
            &.lower-block {
                margin-top: 64px;
                display: flex;
                .left-logos,
                .right-logos {
                    display: flex;
                    align-items: center;
                    .logo {
                        img {
                            max-width: 126px;
                            max-height: 44px;
                        }
                    }
                }
                .left-logos {
                    padding-right: 42px;
                    position: relative;
                    .cross {
                        margin: 8px;
                        width: 41px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: calc(50% - 10px);
                        width: 1px;
                        height: 20px;
                        background: #C6CBD8;
                    }
                }
                .right-logos {
                    padding-left: 42px;
                    .logo {
                        margin-right: 28px;
                    }
                }
            }
        }
    }
    .hero-logos-list {
        display: flex;
        align-items: center;
    }
    #animation_container {
        max-width: 50vw;
        top: -100px;
        right: 0px;
        position: absolute;
    }
    .hero-image {
        display: none;
        position: absolute;
        pointer-events: none;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .hero-image-bottom {
        width: 694px;
        height: 100px;
        position: absolute;
        bottom: 0px;
        left: 0;
        pointer-events: none;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center left;
        background-image: url(${WavesBottom});
        background-blend-mode: multiply;
        mix-blend-mode: multiply;
    }
    @media screen and (max-width: ${breakpoints.xl}) {
        .hero-content {
            padding: 0px;
            padding-top: 28px;
        }
    }
    @media screen and (max-width: ${breakpoints.l}) {
        .hero-content {
            padding: 0px;
            h1 {
                font-size: 50px;
                line-height: 60px;
            }
            .sub-heading {
                margin-top: 24px;
                max-width: 470px;
                span {
                    font-size: 28px;
                    font-weight: 300;
                    line-height: 42px;
                }
            }
            .hero-block {
                &.upper-block {
                    .logo-list-label {
                        margin-right: 8px;
                        font-size: 10px;
                    }
                    .logo {
                        padding: 0 10px !important;
                        margin: 0;
                    }
                }
                &.lower-block {
                    margin-top: 208px;
                }
            }
        }
        #animation_container {
            display: none;
        }
        .hero-image {
            width: 500px;
            height: 500px;
            right: 0px;
            top: 280px;
            display: unset;
            background-position: right center;
            background-image: url(${HomepageHeroGraphicMobile});
        }
        .hero-image-bottom {
            bottom: 260px;
            left: -50px;
        }
    }
    @media screen and (max-width: ${breakpoints.s}) {
        padding: 0;
        .hero-content {
            h1 {
                font-size: 39px;
                line-height: 47px;
            }
            .sub-heading {
                max-width: 470px;
                margin-top: 24px;
            }
            .hero-block {
                &.upper-block {
                    .logo-list-label {
                        margin-right: 8px;
                        font-size: 10px;
                    }
                    .logo {
                        padding: 0 10px !important;
                        margin: 0;
                        img {
                            max-width: 85px;
                            max-height: 30px;
                        }
                    }
                }
                &.lower-block {
                    margin-top: 156px;
                    display: flex;
                    .logo {
                        img {
                            max-height: 32px;
                            max-width: 72px;
                        }
                    }
                    .left-logos {
                        padding-right: 16px;
                        .cross {
                            width: 24px;
                        }
                    }
                    .right-logos {
                        padding-left: 16px;
                            .logo {
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
            .hero-image {
                width: 300px;
                height: 300px;
                right: 0px;
                top: 280px;
                display: unset;
                background-position: right center;
                background-image: url(${HomepageHeroGraphicMobile});
            }
            .hero-image-bottom {
                bottom: 110px;
                left: -200px;
            }
        }
        @media screen and (max-width: ${breakpoints.s}) {
           .hero-content {
                .sub-heading {
                    span {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .hero-block {
                    &.lower-block {
                        .logo {
                            img {
                                max-height: 21px !important;
                                max-width: 73px !important;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default props => (
    <Location>
        {locationProps => <HomepageHero {...locationProps} {...props} />}
    </Location>
)
