import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Root from './../components/common/Root'
import Header from './../components/common/Header'
import PressReleaseHero from '../components/page-components/PressReleaseHero'

export default function Home({ location }) {
  return (
    <Root>
      <Helmet>
        <title>Bitcoin Alliance</title>
        <meta name="description" content="Bitcoin Alliance" />

        <meta property="og:title" content="Bitcoin Alliance" />
        <meta property="og:image" content="https://i.ibb.co/tBTnFMf/Website-Thumbnail-Image-1.png" />
        <meta property="og:description" content="Banco Hipotecario is inviting coders, designers, entrepreneurs, and anyone with amazing ideas to the Bitcoin Bankathon - a fast-paced competition aimed at building innovative Bitcoin-based banking solutions that can unlock access to improved financial services for Salvadoran citizens." />
        <meta property="og:url" content="https://bitcoin-alliance.org/" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="626" />
        <meta property="og:type" content="website" />

        {/*  */}
      </Helmet>
      <Header
        hero={
          <PressReleaseHero/>
        }
      />
    </Root>
  )
}
